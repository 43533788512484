import axios, { AxiosResponse } from 'axios'

export function getFileNameFromResponse(response: AxiosResponse): string {
  const { responseURL } = response.request
  const contentDisposition = response.headers['content-disposition']
  let fileName = ''
  if (responseURL) {
    fileName = decodeURIComponent(responseURL.split('/').pop() || '')
  } else if (contentDisposition && /^attachment/i.test(contentDisposition)) {
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = fileNameRegex.exec(contentDisposition)

    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '')
    }
  }
  return fileName
}

/**
 * @description: 下载服务器静态资源https://static.elven.com
 * @param {string} url
 * @return {void}
 */
export function downloadStaticFile(url: string): void {
  // 创建隐藏的下载链接
  const link = document.createElement('a')
  link.style.display = 'none'
  document.body.appendChild(link)
  axios({
    url: `https://static.elven.com${url}`,
    method: 'GET',
    responseType: 'blob' // 指定响应类型为 blob
  })
    .then((response: AxiosResponse) => {
      if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
        // 兼容性处理：针对IE/Edge浏览器
        ;(window.navigator as any).msSaveBlob(response.data, getFileNameFromResponse(response))
      } else {
        // 创建临时链接下载文件
        const downloadUrl = URL.createObjectURL(response.data)
        link.href = downloadUrl
        link.download = getFileNameFromResponse(response)

        // 模拟点击下载链接
        link.click()

        // 清理临时链接
        URL.revokeObjectURL(downloadUrl)
      }
    })

    .catch((error: any) => {
      console.error('Failed to download file:', error)
    })
    .finally(() => {
      // 移除下载链接
      document.body.removeChild(link)
    })
}

/**
 * @description: 下载文件
 * @param {string} url
 * @return {void}
 */
export function downloadFile(url: string): void {
  const link: any = document.createElement('a')
  link.href = url
  link.target = '_blank'

  // 检查浏览器是否支持 HTML5 的 `download` 属性
  if ('download' in link) {
    link.download = ''
  } else {
    // 对于不支持 `download` 属性的浏览器，使用旧的下载方式
    link.setAttribute('download', '')
    link.onclick = () => {
      window.open(url, '_blank')
      return false
    }
  }
  // 将链接添加到页面中并触发点击事件
  document.body.appendChild(link)
  link.click()

  // 清理生成的链接元素
  document.body.removeChild(link)
}

export function downloadFileByFetchBlob(url: string) {
  if (!url) return
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('网络响应不正常')
      }
      return response.blob()
    })
    .then((blob) => {
      const urlObject = new URL(url)
      const { pathname } = urlObject
      const filename = pathname.substring(pathname.lastIndexOf('/') + 1)
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename || 'download'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(link.href)
    })
    .catch((error) => {
      console.error('下载文件时出现错误:', error)
    })
}

export default { downloadFile, downloadStaticFile, downloadFileByFetchBlob }
